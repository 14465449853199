// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/syQmbCycd";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["iYVbdlec1", "g6wG4iDKe"];

const serializationHash = "framer-2NmFT"

const variantClassNames = {g6wG4iDKe: "framer-v-t0px5", iYVbdlec1: "framer-v-6npihr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Non Included": "g6wG4iDKe", Included: "iYVbdlec1"}

const getProps = ({height, id, text, width, ...props}) => { return {...props, n5rWt_wOh: text ?? props.n5rWt_wOh ?? "Task Management", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "iYVbdlec1"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, n5rWt_wOh, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "iYVbdlec1", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-6npihr", className, classNames)} data-framer-name={"Included"} layoutDependency={layoutDependency} layoutId={"iYVbdlec1"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({g6wG4iDKe: {"data-framer-name": "Non Included"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1r5qhz4"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"H7GtX43Jc"} style={{backgroundColor: "rgb(7, 167, 33)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}} variants={{g6wG4iDKe: {backgroundColor: "rgb(43, 45, 50)"}}}><ComponentViewportProvider ><motion.div className={"framer-5gz1tw-container"} layoutDependency={layoutDependency} layoutId={"mvj4Sh9Rq-container"}><Phosphor color={"var(--token-c40dec17-ad9b-478b-a7f0-e25384b15814, rgb(255, 255, 255))"} height={"100%"} iconSearch={"House"} iconSelection={"Check"} id={"mvj4Sh9Rq"} layoutId={"mvj4Sh9Rq"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"} {...addPropertyOverrides({g6wG4iDKe: {color: "var(--token-b180feb0-2545-4848-a97b-fd8cf92292c7, rgb(146, 150, 159))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1aqdup2"} data-styles-preset={"syQmbCycd"}>Task Management</motion.p></React.Fragment>} className={"framer-31j5bf"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ngFIYgB3W"} style={{"--framer-paragraph-spacing": "0px"}} text={n5rWt_wOh} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2NmFT.framer-a6nqgq, .framer-2NmFT .framer-a6nqgq { display: block; }", ".framer-2NmFT.framer-6npihr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 332px; }", ".framer-2NmFT .framer-1r5qhz4 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 24px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 24px; }", ".framer-2NmFT .framer-5gz1tw-container { flex: none; height: 15px; position: relative; width: 15px; }", ".framer-2NmFT .framer-31j5bf { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 302px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2NmFT.framer-6npihr, .framer-2NmFT .framer-1r5qhz4 { gap: 0px; } .framer-2NmFT.framer-6npihr > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-2NmFT.framer-6npihr > :first-child, .framer-2NmFT .framer-1r5qhz4 > :first-child { margin-left: 0px; } .framer-2NmFT.framer-6npihr > :last-child, .framer-2NmFT .framer-1r5qhz4 > :last-child { margin-right: 0px; } .framer-2NmFT .framer-1r5qhz4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 332
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"g6wG4iDKe":{"layout":["fixed","auto"]}}}
 * @framerVariables {"n5rWt_wOh":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPa_zBtX_U: React.ComponentType<Props> = withCSS(Component, css, "framer-2NmFT") as typeof Component;
export default FramerPa_zBtX_U;

FramerPa_zBtX_U.displayName = "Helper/Points Info";

FramerPa_zBtX_U.defaultProps = {height: 24, width: 332};

addPropertyControls(FramerPa_zBtX_U, {variant: {options: ["iYVbdlec1", "g6wG4iDKe"], optionTitles: ["Included", "Non Included"], title: "Variant", type: ControlType.Enum}, n5rWt_wOh: {defaultValue: "Task Management", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerPa_zBtX_U, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})